import { combineReducers, createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reduxReset from "redux-reset";

// REDUCERS
import {
  authReducer,
  utilizadoresReducer,
  intervencoesReducer,
  senhaReducer,
  obrasReducer,
  filtersReducer,
  notificationsReducer,
  dashboardReducer,
  obrasEnergiasReducer,
  energiasDashboardReducer,
  docsOficiaisReducer,
  fichasTecnicasReducer,
  fabricantesReducer,
  setoresReducer,
  blogReducer,
  filtersEnergiasReducer,
  credenciaisAeroportosReducer,
  plusReducer,
} from "./redux/reducers/index";

const reducer = combineReducers({
  user: authReducer,
  utilizadores: utilizadoresReducer,
  senha: senhaReducer,
  intervencoes: intervencoesReducer,
  obras: obrasReducer,
  filters: filtersReducer,
  notification: notificationsReducer,
  dashboard: dashboardReducer,
  energias: obrasEnergiasReducer,
  energiasDashboard: energiasDashboardReducer,
  documentosOficiais: docsOficiaisReducer,
  fichasTecnicas: fichasTecnicasReducer,
  fabricantes: fabricantesReducer,
  setores: setoresReducer,
  artigos: blogReducer,
  filtersEnergias: filtersEnergiasReducer,
  credenciais: credenciaisAeroportosReducer,
  plus: plusReducer,
});

const middleware = [thunk];

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware), reduxReset())
);

const persistor = persistStore(store);

const rootReducer = (state, action) => {
  console.log(state, action);
  // if (action.type === 'USER_LOGOUT') {
  //   Object.keys(state).forEach((key) => {
  //     storage.removeItem(`persist:${key}`);
  //   });
  //   state = undefined;
  //   console.log('passei pelo root reducer da store');
  // }
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    return reducer(undefined, action);
  }
  return reducer(state, action);
  // console.log('passei pelo root reducer da store');
};

export { store, persistor, rootReducer };
